import React from 'react';
import { HashRouter,Route, Switch } from 'react-router-dom';
import { RouteSConfig } from './routesConfig';
import Header from './components/Header';
import { Spin } from 'antd'

let RoutesArr = [];
setRouteList(RouteSConfig, RoutesArr);
const RoutePage = () => (
  <div className='page_container'>
    <Header />
    <Switch>
      {RoutesArr}
    </Switch >
  </div>
);

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount () {
    this.getNav();
  }

  componentDidUpdate () {
    console.log('update');
  }

  getNav () {
    // GetNavList().then(res => {
    //   window.navList = res;
    //   this.setState({
    //     loading: false
    //   })
    // }).catch(e => {
    //   this.setState({
    //     loading: false
    //   })
    // })
    setTimeout(()=>{
      this.setState({
        loading:false
      })
    },400)
    
  }

  render () {
    const { loading } = this.state;
    return (
      <HashRouter>
        {
          !loading ? (
            <Switch>
              <Route path={'/'} component={RoutePage} />
            </Switch>
          ) : <div className='center_flex' style={{ flex: 1 }}>
              <Spin size="large" />
            </div>
        }

      </HashRouter>
    )
  }
}

function setRouteList (arr, routeList) {
  arr.forEach(element => {
    if (element.children && element.children.length > 0 && element.type !== 'document') {
      element.children.forEach((ele, index) => {
        routeList.push(
          <Route
            path={ele.path}
            component={ele.component}
            exact={ele.exact}
            key={ele.key || index}
          >
          </Route>
        );
      })
    } else if (element.component) {
      routeList.push(
        <Route path={element.path} component={element.component} exact={element.exact}
          key={element.path}>
        </Route>
      );
    }
  });
}