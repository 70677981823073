import React from 'react'
import './index.less'
import { Link, withRouter } from 'react-router-dom'
import right from '@/images/homePage/title_right.png'
import moment from 'moment';
let Time1 = null;
console.log(Time1);
const paths = [
  {
    title: '实时预警',
    path: '/'
  },
  {
    title: '监测管理',
    path: '/minitorManage'
  },
  {
    title: '防控物资',
    path: '/supplies'
  },
  // {
  //   title: '数据分析',
  //   path: '/dataAnalysis'
  // },
  {
    title: '系统设置',
    path: '/systemSettings'
  }
]
// 没有header menu的模块
const nowMenuPathNamrs = ['/bigScreen1','/bigScreen2'];

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      navList: window.navList,
      pathname: this.props.location.pathname,
      hours:moment().format('HH:mm:ss'),
      date:moment().format('YYYY-MM-DD'),
      week:this.getWeek()
    };
  }
  componentDidMount(){
    Time1 = setInterval(()=>{
      this.setState({
        hours:moment().format('HH:mm:ss'),
        date:moment().format('YYYY-MM-DD'),
        week:this.getWeek()
      })
    },1000)
  }
  static getDerivedStateFromProps (nextProps, prevState) {
    const { pathname } = nextProps.location;
    if (pathname !== prevState.pathname) {
      return {
        pathname,
      };
    }
    // 否则，对于state不进行任何操作
    return null;
  }
  getWeek = ()=>{
    const day = moment().day();
    switch(day){
      case 1:
        return '星期一';
      case 2:
        return '星期二';
       
      case 3:
        return '星期三';
      case 4:
        return '星期四';
        
      case 5:
        return '星期五';
      case 6:
        return '星期六';
      case 0:
        return '星期日';
      default:
        break;
    }
  }
  Menu = ()=>{
    const { pathname } = this.state;
    if(nowMenuPathNamrs.indexOf(pathname)!==-1){
      return <div>1233</div>
    }
    const renderArr = [<div className="col_line"  key={'0001'}/>];
    paths.map((ele,i) => {
      renderArr.push( <div key={i} id={"header"+i} className={`menu_list ${ele.path === pathname ? 'selected' : ''}`}>
      <Link className='a_' to={ele.path}>
        {
          ele.title
        }
      </Link>
      </div>);
      renderArr.push(<div key={i + '1'} className="col_line" />);
      return '';
    })
    return renderArr;
  }
  RightEle = ()=>{
    const { pathname,date,hours,week} = this.state;
    if(nowMenuPathNamrs.indexOf(pathname)!==-1){
      return  <div className="header_right_title"  key={'004'}>
        <img alt="" className='header_left_icon' src={right} />
        <div className="right_groups">
          <div className="right_week">{week}</div>
          <div className="right_date">{date}</div>
        </div>
        <div className="right_hours">{hours}</div>
    </div>
    }
    return [<div className="col_line" key={'001'}/>,
    <div className='menu_list'  key={'002'}>
      <div className='a_'>
        登出
       </div>
    </div>,
    <div style={{ marginRight: 0 }}  key={'003'} className="col_line" />]
  }
  render () {
    return (
      <div className='header_common'>
        <div className="header_common_title_container">
          <div className='header_common_title_container_left'>
            <div className="header_common_title">
              ERMAI 疫情防控预警系统
            <img alt="" className='header_right_icon' src={right} />
            </div>
            <div className="menu_container">
              <this.Menu ></this.Menu>
            </div>
          </div>
          <div className="login_out_container menu_container">
            <this.RightEle></this.RightEle>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)