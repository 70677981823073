import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { App } from './routes';
import './common/reset.css'
import './common/common.less';
import '@tools/rem';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
ReactDOM.render(
  <ConfigProvider locale={zhCN}><App /></ConfigProvider>,
  document.getElementById('root')
);



serviceWorker.unregister();