import { asyncComponent } from './components/asyncComponent';

const HomePage = asyncComponent(() => import('./widgets/homepage'));
const MinitorManage = asyncComponent(() => import('./widgets/monitorManage'));
const Device = asyncComponent(() => import('./widgets/monitorManage/device'));
const DeviceData = asyncComponent(() => import('./widgets/monitorManage/deviceData'));
const UserDetail = asyncComponent(() => import('./widgets/monitorManage/userDetail'));
const Supplies = asyncComponent(()=>import('./widgets/supplies'))
const DataAnalysis = asyncComponent(()=>import('./widgets/dataAnalysis'))
const SystemSettings = asyncComponent(()=>import('./widgets/systemSettings'))
const BigScreen1 = asyncComponent(()=>import('./widgets/bigScreen/bigScreen1'))
const BigScreen2 = asyncComponent(()=>import('./widgets/bigScreen/bigScreen2'))
export const RouteSConfig = [
    /* {
        title: '首页',
        path: '/',
        children: [
            {
                title: '党组织考核',
                path: '/examine/examine/org',
                component: HomePage,
                exact: true,
            },
            {
                title: '党员考核',
                path: '/examine/examine/member',
                component: HomePage,
                exact: true,
            },
        ],
    }, */
    {
        title: '首页',
        path: '/',
        component: HomePage,
        exact: true,
    },
    {
        title: 'bigScreen1',
        path: '/bigScreen1',
        component: BigScreen1,
        exact: true,
    },
    {
        title: 'bigScreen2',
        path: '/bigScreen2',
        component: BigScreen2,
        exact: true,
    },
    {
        title: '监测管理',
        path: '/minitorManage',
        component: MinitorManage,
        exact: true,
    },
    {
        title: '防护物资',
        path: '/supplies',
        component: Supplies,
        exact: true,
    },
    {
        title: '数据分析',
        path: '/dataAnalysis',
        component: DataAnalysis,
        exact: true,
    },
    {
        title: '系统设置',
        path: '/systemSettings',
        component: SystemSettings,
        exact: true,
    },
    {
        title: '设备',
        path: '/device',
        component: Device,
        exact: true,
    },
    {
        title: '设备数据',
        path: '/deviceData',
        component: DeviceData,
        exact: true,
    },
    {
        title: '人员',
        path: '/UserDetail',
        component: UserDetail,
        exact: true,
    },
]