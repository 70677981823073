/**@flow*/
import * as React from 'react'

class MyLifeCircle extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			children: props.children,
			path: props.path,
			title: props.title,
		};
	}

	render () {
		const { children } = this.state;
		return children
	}
}

export { MyLifeCircle }